<script>
import appConfig from "@/app.config";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { required } from "vuelidate/lib/validators";
import moment, { min } from "moment";

import Swal from "sweetalert2";

/**
 * Add-product component
 */
export default {
  components: {
    FullCalendar,
  },

  data() {
    return {
      title: "Schedule",
      processing: false,
      items: [
        {
          text: "Schedule",
        },
        {
          text: "Schedule",
          active: true,
        },
      ],
      currentTabComponent: null,
      tabs: [],
      renderCalendar: true,
      calendarOptions: {
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: null,
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        // dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
      eventModal: false,
      releaseShiftModel: false,
      categories: [],
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: null,
        category: null,
      },
      editevent: {
        editTitle: null,
        shift: {},
      },
      selectedShifts: [],
      class_name: null,
      status: null,
      select_release_reason: null,
      release_reason: null,
      release_status: null,
      release_document: null,
      shift_id: null,
      timeline_id: null,
      current_timeline_id: null,
      area_role_id: null,
      counts: null,
      shiftSelectedTime: { from: null, to: null, type: null },
    };
  },
  mounted() {
    this.fetchSelectedShift();
  },

  methods: {
    fetchSelectedShift() {
      this.$axios
        .get("shift/employee/selected-shifts", {params : {employee_id : this.currentUser.employee.id}})
        .then((response) => {
          let data = response.data.payload;
          this.selectedShifts = data;
          this.fetchShift();
        })
        .catch((error) => {
          console.error(error);
        })
    },
    fetchShift() {
      this.$axios
        .get(
          "shift-timeline/get-approved-shifts/" + this.currentUser.employee.id
        )
        .then((response) => {
          let data = response.data.payload;

          this.counts = [];
          let i = 1;
          let checKObject = false;
          for (const [key, value] of Object.entries(data)) {
            this.tabs.push({
              id: i,
              desc: key,
              component_name: key,
              shifts: value,
            });
            i = i + 1;
            checKObject = true;
          }
          if (checKObject) {
            this.currentTabComponent = this.tabs[0].desc;
            this.buildEvents(this.tabs[0].shifts);
          }
          // this.buildEvents(data['shifts'].FOH);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    buildEvents(shifts) {
      this.calendarOptions.initialEvents = shifts.map((timeline) => {
        // this.shifts.push(shift);
        this.class_name = "primaryColorBg text-white";
        this.status = null;

        let forPremium = "";
        if (timeline.shift.shift_type == "Premium") {
          forPremium = " (" + timeline.shift.shift_type + ")*";
        }
        return {
          id: timeline.id,
          title: timeline.shift.name + " " + forPremium,
          start: new Date(timeline.from),
          end: new Date(timeline.to),
          className: this.class_name,
          status: timeline.status,
          backgroundColor: timeline.shift.label_color,
          shift: timeline,
        };
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.releaseShiftModel = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars

    releaseShift() {
      this.submit = true;

      this.closeModal();

      let self = this;
      if (self.processing) {
        return;
      }
      self.processing = true;
      if (self.select_release_reason != "other") {
        self.release_reason = self.select_release_reason;
      }
      let fd = new FormData();
      fd.append("release_docs", self.release_document);
      fd.append("status", self.release_status);
      fd.append("area_role_id", self.area_role_id);
      fd.append("shift_id", self.shift_id);
      fd.append("slot_id", self.slot_id);
      fd.append("employee_id", self.currentUser.employee.id);
      fd.append("action_detail", self.release_reason);

      self.$axios
        .post("shift/employee/shift-action/" + self.current_timeline_id, fd , {params : {validateRelease : true}})
        .then((response) => {
          let data = response.data;

          self.triggerSwal(data.message, "success");
          // location.reload();
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(function () {
          self.processing = false;
          self.release_reason = null;
          self.select_release_reason = null;
          self.release_status = null;
          self.release_document = null;
        });
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.releaseShiftModel = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
    
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.status = this.edit.extendedProps.status;
      this.eventModal = false;
    },

    closeModal() {
      this.eventModal = false;
      this.releaseShiftModel = false;
    },

    handleEvents(events) {
      this.currentEvents = events;
    },

    changeTab(tab) {
      this.renderCalendar = false;
      this.currentTabComponent = tab.component_name;
      this.buildEvents(tab.shifts);
      this.$nextTick(() => {
        this.renderCalendar = true;
      });
    },
  },

  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <div class="">
    <!-- <div class="card-body"> -->
    <!-- <h2 class="f-20 poppins">Approved Shifts</h2> -->
    <div class="row" v-if="calendarOptions.initialEvents != null">
      <div class="row">
        <div class="col-12 col-md mb-3 card-header">
          <ul class="nav tabs-pills" role="tablist">
            <li class="nav-item f-20" v-for="(tab, index) in tabs" :key="index">
              <a
                class="nav-link d-flex align-items-center"
                :class="{
                  'border-bottom border-pink':
                    currentTabComponent === tab.component_name,
                }"
                :id="tab.id"
                @click="changeTab(tab)"
                data-toggle="tab"
                href="#area"
                aria-controls="area"
                role="tab"
                aria-selected="true"
              >
                <i class=""></i
                ><span
                  :class="{
                    'text-gradientColor':
                      currentTabComponent === tab.component_name,
                  }"
                  class="d-none d-sm-block"
                  >{{ tab.desc }}</span
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body under-tabs">
          <div class="row">
            <div class="col-12">
              <div class="tab-content">
                <FullCalendar
                  v-if="renderCalendar"
                  ref="fullCalendar"
                  :options="calendarOptions"
                ></FullCalendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

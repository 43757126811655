<script>
import Layout from "./layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import shiftApproved from "@/views/pages/employee/components/shiftApproved";
// import shiftRelease from "@/views/pages/employee/components/shiftRelease";
import shiftRequest from "@/views/pages/employee/components/shiftRequest";
import shiftRejected from "@/views/pages/employee/components/shiftRejected";
import calendar from "@/views/pages/employee/components/calendar";
import pickReleasedShiftVue from "./components/pickReleasedShift.vue";
import scheduleComponent from "@/views/pages/employee/schedule/schedules-component.vue";
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    scheduleComponent,
    Layout,
    PageHeader,
    shiftApproved,
    // shiftRelease,
    shiftRequest,
    shiftRejected,
    // calendar,
    pickReleasedShiftVue,
  },

  data() {
    return {
      // showShift: false,
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],

      shifts: [],
      released_shifts: [],
      approved_shifts: [],
      pending_shifts: [],
      rejected_shifts: [],
      locations: [],
      location_id: null,
      countsLoading : false,
      statCounts: {},
      page_size : 5,
      
      released_shifts_status : 'Released',
      rejected_shifts_status : 'Rejected',
      pending_shifts_status : 'Pending',
      approved_shifts_status : 'Accepted',

      approvedShifts_nextPageUrl : null,
      releasedShifts_nextPageUrl : null,
      rejectedShifts_nextPageUrl : null,
      pendingShifts_nextPageUrl : null,
    };
  },
  created() {
    this.user = this.$store.state.auth.currentUser;
  },
  mounted() {
    this.fetchEmployeeLocations();
  },
  methods: {
    fetchApprovedShiftRequests(url) {
      url = url ?? '/shift/employee/index';
      this.$axios
        .get(url , {params : {location_id : this.location_id ,  employee_id : this.user.employee.id  , status : this.approved_shifts_status , length : this.page_size}})
        .then((response) => {
          let data = response.data.payload;
          this.approved_shifts.push(...data.data);
          this.approvedShifts_nextPageUrl = data.next_page_url;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(function () {});
    },

    fetchReleasedShiftRequests(url) {
      url = url ?? '/shift-timeline/edit';
      this.$axios
        .get(url , {params : {location_id : this.location_id , length : this.page_size}})
        .then((response) => {
          let data = response.data.payload;
          this.released_shifts.push(...data.data);
          this.releasedShifts_nextPageUrl = data.next_page_url;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    fetchPendingShiftRequests(url) {
      url = url ?? '/shift/employee/index';
      this.$axios
        .get(url , {params : {location_id : this.location_id , employee_id : this.user.employee.id , status : this.pending_shifts_status , length : this.page_size}})
        .then((response) => {
          let data = response.data.payload;
  
          this.pending_shifts.push(...data.data);
          this.pendingShifts_nextPageUrl = data.next_page_url;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    fetchRejectedShiftRequests(url) {
     url = url ?? '/shift/employee/index';
      this.$axios
        .get(url , {params : {location_id : this.location_id , employee_id : this.user.employee.id , status : this.rejected_shifts_status , length : this.page_size}})
        .then((response) => {
          let data = response.data.payload;
  
          this.rejected_shifts.push(...data.data);
          this.rejectedShifts_nextPageUrl = data.next_page_url;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchEmployeeLocations()
    {
      this.$axios.get('employee/team-member/' + this.user.id).then((response) => {
        let res_data = response.data.payload;
        this.locations = res_data.employee.locations;
        this.location_id = this.locations[0].id;
        this.statsCount();
        this.fetchReleasedShiftRequests();
        this.fetchApprovedShiftRequests();
        this.fetchPendingShiftRequests();
        this.fetchRejectedShiftRequests();
      })
    },

    fetchStats()
    {
        this.released_shifts = [],
        this.approved_shifts= [],
        this.pending_shifts= [],
        this.rejected_shifts= [],
        this.statsCount();
        this.fetchReleasedShiftRequests();
        this.fetchApprovedShiftRequests();
        this.fetchPendingShiftRequests();
        this.fetchRejectedShiftRequests();
        
    },

    statsCount()
    {
      this.countsLoading = true;
      this.$axios.get('dashboard/employee-dashboard-stats/' + this.user.employee.id , {params : {location_id : this.location_id}}).then(response => {
        let data_count = response.data.payload;
        this.statCounts = data_count
        if (data_count.length > 0) {
          this.statCounts = data_count[0];
        } else {
          this.statCounts = {};
        }
        this.countsLoading = false;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => (this.countsLoading = false));
    },
    seeMoreApprovedShifts()
    {
      this.fetchApprovedShiftRequests(this.approvedShifts_nextPageUrl);
    },
    seeMoreReleasedShifts()
    {
      this.fetchReleasedShiftRequests(this.releasedShifts_nextPageUrl);
    },
    seeMorePendingShifts()
    {
      this.fetchPendingShiftRequests(this.pendingShifts_nextPageUrl);
    },
    seeMoreRejectedShifts()
    {
      this.fetchRejectedShiftRequests(this.rejectedShifts_nextPageUrl);
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" class="poppins fw-normal"  />

     <div class="row align-items-center">
        <div class="col-md-6">
          <router-link to="/" v-if="isManager()" class="d-flex align-items-center">
            <button class="btn primaryColorBg text-white">
              <i class="fa fa-key mx-2"></i>Switch to Manager
            </button>
          </router-link>
        </div>
        <div class="col-md-6 mb-3 mx-auto me-0">
          <div class="d-flex align-items-center gap-2">
            <div class="icon-wrapper mt-3 pt-1">
            </div>
            <div class="form-field__wrapper w-100">
              <label>Select Location</label>

              <div class="border input-group rounded shadow">
                <div class="bg-white border-0 input-group-text">
                  <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                </div>
                <select v-model="location_id" id="emp_job_type" class="form-select border-0" @change="fetchStats()" >
                  <option :value="location.id" v-for="(location, index) in locations" :key="index">
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
    </div>
   
    <div class="row">
      <div class="col-12">
        <div class="d-grid gap-2 grid_inline">
          <div class="card rounded box-shadow px-3 py-3">
            <router-link to="#">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/location_icon.png">
                <h2 class="text-dark f-30 pt-1">{{locations.length}}</h2>
                <p class="text-dark mb-2">Total Locations</p>
                <span class="secondaryColor">Enrolled</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'Accepted' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/active_shifts_icon.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.active_Shifts}}</h2>
                <p class="text-dark mb-2">Total Active Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'NewShifts' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/bonus_shifts.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.bonus_shifts}}</h2>
                <p class="text-dark mb-2">Bonus Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/employee-schedule'}">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/upcomming_week.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.progress_shifts}}</h2>
                <p class="text-dark mb-2">Upcoming Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/employee-schedule'}">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/upcomming_week.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.current_month_shifts}}</h2>
                <p class="text-dark mb-2">Upcoming Shifts</p>
                <span class="secondaryColor">This Month</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'ApplyForLeave' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/leaves_apply.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.leavesApplied}}</h2>
                <p class="text-dark mb-2">Leaves Applied</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'NewShifts' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/open_shifts.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.open_shifts_count}}</h2>
                <p class="text-dark mb-2">Vacant Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>
          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'Released' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/dropped_shifts.png">
                <h2 class="text-dark f-30 pt-1">{{statCounts.released_shifts}}</h2>
                <p class="text-dark mb-2">Dropped Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

<div class="admin_calendar">
  <scheduleComponent  />
</div>
    

    <shiftApproved :shifts="approved_shifts" :next_page_url="approvedShifts_nextPageUrl" @seeMoreApprovedShifts="seeMoreApprovedShifts" />

    <pickReleasedShiftVue :shifts="released_shifts" :next_page_url="releasedShifts_nextPageUrl" @seeMoreReleasedShifts="seeMoreReleasedShifts"></pickReleasedShiftVue>

    <!-- <shiftRelease :shifts ="shifts"/>   -->

    <shiftRequest :shifts="pending_shifts" :next_page_url="pendingShifts_nextPageUrl" @seeMorePendingShifts="seeMorePendingShifts" />

    <shiftRejected :shifts="rejected_shifts" :next_page_url="rejectedShifts_nextPageUrl" @seeMoreRejectedShifts="seeMoreRejectedShifts" />

    <!-- <calendar
      v-if="
        currentUser.employee.status == 'Active' ||
        currentUser.employee.status == 'Updated'
      "
    /> -->
  </Layout>
</template>

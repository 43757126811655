var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.calendarOptions.initialEvents != null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md mb-3 card-header"},[_c('ul',{staticClass:"nav tabs-pills",attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:index,staticClass:"nav-item f-20"},[_c('a',{staticClass:"nav-link d-flex align-items-center",class:{
                'border-bottom border-pink':
                  _vm.currentTabComponent === tab.component_name,
              },attrs:{"id":tab.id,"data-toggle":"tab","href":"#area","aria-controls":"area","role":"tab","aria-selected":"true"},on:{"click":function($event){return _vm.changeTab(tab)}}},[_c('i',{}),_c('span',{staticClass:"d-none d-sm-block",class:{
                  'text-gradientColor':
                    _vm.currentTabComponent === tab.component_name,
                }},[_vm._v(_vm._s(tab.desc))])])])}),0)])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body under-tabs"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tab-content"},[(_vm.renderCalendar)?_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions}}):_vm._e()],1)])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }